import { useState, useEffect } from "react";
import './TopMenu.css';
import languages from "../../languages.json";

function TopMenu(props) {
  const [language, setLanguage] = useState("ua");

  useEffect(() => {
    const lang = window.location.hash.replace("#", "");

    if (lang) {
      setLanguage(lang);
    }
  }, [])

  useEffect(() => {
    if (props?.language) {
      setLanguage(props.language);
    }
  }, [props])

  return (
    <div className="top_menu_container">
        <div className="top_menu">
            <a className="menu_item" href="/">{languages[language].general}</a>
            <a className="menu_item" href={"/reports" + "#" + language}>{languages[language].reports}</a>
        </div>
    </div>
  );
}

export default TopMenu;
