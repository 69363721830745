import { useState, useEffect } from "react";
import './Landing.css';
import ic_youtube_color from '../../assets/ic_youtube-color.svg';
import ic_instagram_color from "../../assets/ic_instagram-color.svg";
import ic_facebook_color from "../../assets/ic_facebook-color.svg";
import ic_telegram_color from "../../assets/ic_telegram-color.svg";
import map_ua from "../../assets/map-ua.png";
import logo from "../../assets/logo_uk.png";
import icon1 from "../../assets/icon-1.svg";
import icon2 from "../../assets/icon-2.svg";
import icon3 from "../../assets/icon-3.svg";
import icon4 from "../../assets/icon-4.svg";
import icon5 from "../../assets/icon-5.svg";
import icon6 from "../../assets/icon-6.svg";
import icon7 from "../../assets/icon-7.svg";
import icon8 from "../../assets/icon-8.svg";
import icon9 from "../../assets/icon-9.svg";
import icon10 from "../../assets/icon-10.svg";
import icon11 from "../../assets/icon-11.svg";
import ukraine_hryvnia_icon from "../../assets/ukraine-hryvnia-icon.svg";
import currency_icon_dollars from "../../assets/currency-icon_dollars.svg";
import currency_icon_euro from "../../assets/currency-icon_euro.svg";
import currency_icon_zloty from "../../assets/currency-icon_zloty.svg";
import currency_icon_canadian_dollars from "../../assets/currency-icon_canadian-dollars.svg";
import currency_icon_pounds from "../../assets/currency-pounds_sterling.svg";
import currency_icon_bitcoin from "../../assets/currency-icon_bitcoin.svg";
import ic_phone from "../../assets/ic_phone.svg";
import ic_facebook from "../../assets/ic_facebook.svg";
import ic_instagram from "../../assets/ic_instagram.svg";
import ic_telegram from "../../assets/ic_telegram.svg";
import ic_youtube from "../../assets/ic_youtube.svg";
import languages from "../../languages.json";
import TopMenu from "../../components/TopMenu/TopMenu";

function Landing() {
  const [language, setLanguage] = useState("ua");

  useEffect(() => {
    const lang = window.location.hash.replace("#", "");
    if (lang) {
      setLanguage(lang);
    }
  })

  const scrollToBills = () => {
    // scroll to id bills-link
    const element = document.getElementById("bills-link");
    element.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="Landing">
      <TopMenu language={language}/>
      <header class="header">
            <section class="section section_navigation navigation">
                <div class="container"><img src={logo} alt="Студентське Братство Львівщини" class="navigation_logo"/>
                    <div class="navigation__social-lang social-lang">
                        <div class="navigation__social social">
                            <a class="social__link" href="https://www.youtube.com/channel/UClFgo2L140Vn7V9ubAryUVg" target="_blank">
                                <div class="social__icon"><img src={ic_youtube_color}/></div>
                            </a>
                            <a class="social__link" href="https://www.instagram.com/sblviv/" target="_blank">
                                <div class="social__icon"><img src={ic_instagram_color}/></div>
                            </a>
                            <a class="social__link" href="https://www.facebook.com/sblviv" target="_blank">
                                <div class="social__icon"><img src={ic_facebook_color}/></div>
                            </a>
                            <a class="social__link" href="https://t.me/sblinfo" target="_blank">
                                <div class="social__icon"><img src={ic_telegram_color}/></div>
                            </a>
                        </div>
                        <div class="navigation_lang lang">
                          <a class={language === 'ua' ? "lang_link link link_active" : "lang_link link"} href="#ua" onClick={() => setLanguage('ua')}>UA</a>
                          <a class={language === 'en' ? "lang_link link link_active" : "lang_link link"} href="#en" onClick={() => setLanguage('en')}>EN</a>
                          <a class={language === 'pl' ? "lang_link link link_active" : "lang_link link"} href="#pl" onClick={() => setLanguage('pl')}>PL</a>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section_first first">
                <div class="container">
                    <h1 class="first_title">{languages[language].first_title}</h1>
                </div>
            </section>
        </header>
        <main class="main">
            <section class="section section_help help">
                <div class="container">
                    <div class="wrap-img"><img src={map_ua} alt="мапа України" class="help_map"/></div>
                    <div class="wrap-text">
                        <h3 class="help_text text text_first">{languages[language].text_first}</h3>
                        <h3 class="help_text text text_secondary"><b>{languages[language].text_secondary}</b></h3><button onClick={() => scrollToBills()} class="button">{languages[language].help}</button>
                    </div>
                </div>
            </section>
            <section class="section section_necessary necessary">
                <div class="container">
                    <h2 class="necessary_title"><b>{languages[language].necessary_title_b}</b> {languages[language].necessary_title}</h2>
                    <ul class="list list_first">
                        <li class="list_item">
                            <div class="list_icon"><img src={icon1}/></div>
                            <h5>{languages[language].item1}</h5>
                        </li>
                        <li class="list_item">
                            <div class="list_icon"><img src={icon2}/></div>
                            <h5>{languages[language].item2}</h5>
                        </li>
                        <li class="list_item">
                            <div class="list_icon"><img src={icon3}/></div>
                            <h5>{languages[language].item3}</h5>
                        </li>
                    </ul>
                    <ul class="list list_second">
                        <li class="list_item">
                            <div class="list_icon"><img src={icon4}/></div>
                            <h5>{languages[language].item4}</h5>
                        </li>
                        <li class="list_item">
                            <div class="list_icon"><img src={icon5}/></div>
                            <h5>{languages[language].item5}</h5>
                        </li>
                        <li class="list_item">
                            <div class="list_icon"><img src={icon6}/></div>
                            <h5>{languages[language].item6}</h5>
                        </li>
                    </ul>
                    <ul class="list list_third">
                        <li class="list_item">
                            <div class="list_icon"><img src={icon7}/></div>
                            <h5>{languages[language].item7}</h5>
                        </li>
                        <li class="list_item">
                            <div class="list_icon"><img src={icon8}/></div>
                            <h5>{languages[language].item8}</h5>
                        </li>
                        <li class="list_item">
                            <div class="list_icon"><img src={icon9}/></div>
                            <h5>{languages[language].item9}</h5>
                        </li>
                        <li class="list_item">
                            <div class="list_icon"><img src={icon10}/></div>
                            <h5>{languages[language].item10}</h5>
                        </li>
                        <li class="list_item">
                            <div class="list_icon"><img src={icon11}/></div>
                            <h5>{languages[language].item11}</h5>
                        </li>
                    </ul>
                </div>
            </section>
            <section class="section section_bills bills">
                <div class="container">
                    <h2 class="bills_title" id="bills-link"><b>{languages[language].bills_title}</b></h2>
                    <div class="wrap-currency">
                        <div class="currency-icon currency-icon_dollars"><img src={ukraine_hryvnia_icon}/></div>
                        <div class="wrap-table wrap-table_dollars">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b><h3>{languages[language].in_UAH} (UAH)</h3></b></td>
                                    </tr>
                                    <tr>
                                        <td><b>{languages[language].bills_item1_title}</b></td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].bills_item1_address}</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].IBAN}</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].Bank_Code}</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].EDRPOU}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="wrap-currency">
                        <div class="currency-icon currency-icon_dollars"><img src={currency_icon_dollars}/></div>
                        <div class="wrap-table wrap-table_dollars">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b><h3>{languages[language].in_USD} (USD)</h3></b></td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].middle_bank} BANK OF NEW YORK MELLON, New York, USA</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: IRVTUS3N</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver_bank} JSC KREDOBANK Lviv, Ukraine</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: WUCBUA2X</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver} HS SBL</td>
                                    </tr>
                                    <tr>
                                        <td>IBAN: <b>UA363253650000000260010034841</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="wrap-currency">
                        <div class="currency-icon currency-icon_euro"><img src={currency_icon_euro}/></div>
                        <div class="wrap-table wrap-table_euro">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b><h3>{languages[language].in_EUR} (EUR)</h3></b></td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].middle_bank} KBC BANK NV, Brussels, Belgium</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: KREDBEBB</td>
                                    </tr>
                                    <tr>
                                        <td><b>{languages[language].or}</b></td>
                                    </tr>
                                    <tr>
                                        <td>COMMERZBANK AG, Frankfurt, Germany</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: COBADEFF</td>
                                    </tr>
                                    <tr>
                                        <td><b>{languages[language].or}</b></td>
                                    </tr>
                                    <tr>
                                        <td>UNICREDIT S.P.A., Milano, Italy</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: UNCRITMM</td>
                                    </tr>
                                    <tr>
                                        <td><b>{languages[language].or}</b></td>
                                    </tr>
                                    <tr>
                                        <td>RAIFFEISEN BANK INTERNATIONAL AG, Vienna, Austria</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: RZBAATWW</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver_bank} JSC KREDOBANK Lviv, Ukraine</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: WUCBUA2X</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver} HS SBL</td>
                                    </tr>
                                    <tr>
                                        <td>IBAN: <b>UA983253650000000260080034842</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="wrap-currency">
                        <div class="currency-icon currency-icon_hryvnia"><img src={currency_icon_zloty}/></div>
                        <div class="wrap-table wrap-table_hryvnia">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b><h3>{languages[language].in_PLN} (PLN)</h3></b></td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].middle_bank} POWSZECHNA KASA OSZCZEDNOSCI BANK POLSKI SA WARSZAWA, POLAND</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: BPKOPLPW</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver_bank} JSC KREDOBANK Lviv, Ukraine</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: WUCBUA2X</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver} HS SBL</td>
                                    </tr>
                                    <tr>
                                        <td>IBAN: <b>UA193253650000000260050034843</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="wrap-currency">
                        <div class="currency-icon currency-icon_hryvnia"><img src={currency_icon_canadian_dollars}/></div>
                        <div class="wrap-table wrap-table_hryvnia">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b><h3>{languages[language].in_CAD} (CAD)</h3></b></td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].cor_receiver} CANADIAN IMPERIAL BANK OF COMMERCE Toronto, Canada</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: CIBCCATT</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].middle_bank} KBC BANK NV, Brussels, Belgium</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: KREDBEBB</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver_bank} JSC KREDOBANK Lviv, Ukraine</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: WUCBUA2X</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver} HS SBL</td>
                                    </tr>
                                    <tr>
                                        <td>IBAN: <b>UA373253650000000260020034844</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="wrap-currency">
                        <div class="currency-icon currency-icon_ewallet"><img src={currency_icon_pounds}/></div>
                        <div class="wrap-table wrap-table_ewallet">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b><h3>{languages[language].in_GBP} (GBP)</h3></b></td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].cor_receiver} ROYAL BANK OF SCOTLAND, LONDON</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: RBOSGB2L</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].middle_bank} KBC BANK NV Brussels, Belgium</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: KREDBEBB</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver_bank} JSC KREDOBANK Lviv, Ukraine</td>
                                    </tr>
                                    <tr>
                                        <td>SWIFT: WUCBUA2X</td>
                                    </tr>
                                    <tr>
                                        <td>{languages[language].receiver} HS SBL</td>
                                    </tr>
                                    <tr>
                                        <td>IBAN: <b>UA023253650000000260090034845</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="wrap-currency">
                        <div class="currency-icon currency-icon_ewallet"><img src={currency_icon_bitcoin}/></div>
                        <div class="wrap-table wrap-table_ewallet">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b><h3>{languages[language].crypto_wallets}</h3></b></td>
                                    </tr>
                                    <tr>
                                        <td>USDT</td>
                                    </tr>
                                    <tr>
                                        <td>TRC 20: <b>TX2WNiAvygL2Sm1UiCiMoWCfsmq7hZjihf</b></td>
                                    </tr>
                                    <tr>
                                        <td>BTC</td>
                                    </tr>
                                    <tr>
                                        <td><b>1N1uCsDnW4bfKaGsj2xbgeuk4qMj7C1uBS</b></td>
                                    </tr>
                                    <tr>
                                        <td>ETH</td>
                                    </tr>
                                    <tr>
                                        <td>ERC20: <b>0x7de3eaadf10228dfe6d9f7c46000b441786f3fde</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section_contacts">
                <div class="container"><a class="contact__wrap" href="tel:+380636218291" target="_blank">
                        <div class="contact__icon"><img src={ic_phone}/></div>
                        <div class="contact__value">+380 (63) 621-82-91</div>
                    </a><a class="contact__wrap" href="tel:+380971765813" target="_blank">
                        <div class="contact__icon"><img src={ic_phone}/></div>
                        <div class="contact__value">+380 (99) 448-51-35</div>
                    </a><a class="contact__wrap" href="https://www.facebook.com/sblviv" target="_blank">
                        <div class="contact__icon"><img src={ic_facebook}/></div>
                        <div class="contact__value">Facebook</div>
                    </a><a class="contact__wrap" href="https://www.instagram.com/sblviv/" target="_blank">
                        <div class="contact__icon"><img src={ic_instagram}/></div>
                        <div class="contact__value">Instagram</div>
                    </a><a class="contact__wrap" href="https://t.me/sblinfo" target="_blank">
                        <div class="contact__icon"><img src={ic_telegram}/></div>
                        <div class="contact__value">Telegram</div>
                    </a><a class="contact__wrap" href="https://www.youtube.com/channel/UClFgo2L140Vn7V9ubAryUVg" target="_blank">
                        <div class="contact__icon"><img src={ic_youtube}/></div>
                        <div class="contact__value">Youtube</div>
                    </a></div>
            </section>
        </main>
        <footer class="footer">
            <div class="container">
                <h4>{languages[language].help_Ukraine}<br/>{languages[language].save_eu}</h4>
            </div>
        </footer>
    </div>
  );
}

export default Landing;
