import { useState, useEffect } from "react";
import './Reports.css';
import languages from "../../languages.json";
import reports_pdf from "./sbl.pdf";
import TopMenu from "../../components/TopMenu/TopMenu";

function Reports() {
  const [language, setLanguage] = useState("ua");

  useEffect(() => {
    const lang = window.location.hash.replace("#", "");
    if (lang) {
      setLanguage(lang);
    }
  })

  return (
    <div className="Reports">
      <TopMenu/>
      <iframe className="pdf-viewer" src={reports_pdf}></iframe>
    </div>
  );
}

export default Reports;
