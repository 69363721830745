import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Landing from "./views/Landing/Landing";
import Reports from "./views/Reports/Reports";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/reports" element={<Reports />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
